import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import QRCodeStyling from 'qr-code-styling';

import LogoSrc from '@/@assets/logomark-2.svg';
import { useElementSize } from '@/hooks/useElementSize';

import {
  GAP_PX,
  HeaderWrapper,
  Image,
  IMAGE_MARGIN_TOP_PX,
  MARGIN_VERTICAL_PX,
  PageConfig,
  QRCode,
  Separator,
  StoryPageContent,
  SummaryItem,
  SummaryText,
  SummaryWrapper,
  Title,
} from './BookStoryContent.styles';

interface RemoteData {
  story: {
    title: string;
    summary: string;
    url: string;
    imageUrl: string | null;
  };
}

const TEST_DATA: RemoteData = {
  story: {
    title: 'Archer Mock',
    summary: [
      'The Archer class in Ragnarok Online is a formidable and versatile ranged attacker, specializing in precise long-range attacks and exceptional agility. Masters of the bow and arrow, Archers are known for their accuracy and the ability to deal damage from a safe distance, making them valuable assets in both solo and party play.',
      'One of      the defining features of the Archer class is its proficiency in utilizing various types of arrows, each with unique effects and properties. Archers can adapt their strategy by choosing the right arrows for different situations, whether it be dealing extra damage to specific monster types or inflicting status ailments on opponents.',
      '  As Archers progress in their journey, they can advance to more specialized classes, such as the Hunter or the Bard/Dancer. The Hunter excels in hunting and trapping, employing a diverse set of skills to capture and defeat monsters efficiently. On the other hand, the Bard/Dancer is more focused on providing support to allies through buffs, debuffs, and crowd control.',
    ].join('\n\n'),
    url: 'https://www.google.com/',
    imageUrl: 'https://images.pexels.com/photos/1402787/pexels-photo-1402787.jpeg',
    // imageUrl: null,
  },
};

const PPI = 96;
const USABLE_PAGE_HEIGHT = 10 * PPI - MARGIN_VERTICAL_PX * 2;

export function BookStoryContent() {
  const [data, setData] = useState<RemoteData | null>(null);
  const [qrCode, setQrCode] = useState<string>('');

  useEffect(() => {
    axios
      .get<RemoteData>('/__data__.json')
      .then(
        (response) => response.data,
        () => TEST_DATA,
      )
      .then(async (remoteData) => {
        const qrCode = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'svg',
          data: remoteData.story.url,
          image: LogoSrc,
          dotsOptions: {
            type: 'dots',
            color: '#939e9e',
          },
          backgroundOptions: {
            color: 'white',
          },
          imageOptions: {
            imageSize: 0.5,
            margin: 2,
          },
          qrOptions: {
            errorCorrectionLevel: 'M',
          },
        });
        const svgData = await qrCode.getRawData('svg');
        if (svgData == null) throw new Error('ERROR_GENERATING_QR_CODE');

        setQrCode(URL.createObjectURL(svgData));
        setData(remoteData);
      });
  }, []);

  // Elements size
  const [headerRef, headerSize] = useElementSize<HTMLAnchorElement>();
  // The -3 is just to compensate for rounding errors in the math.
  const maxImageHeight = USABLE_PAGE_HEIGHT - headerSize.height - GAP_PX - IMAGE_MARGIN_TOP_PX - 3;

  if (data === null) {
    return null;
  }

  const textChunks = data.story.summary.split(/\n/);

  return (
    <>
      <Helmet>
        <style>{PageConfig}</style>
      </Helmet>
      <StoryPageContent>
        <HeaderWrapper ref={headerRef} href={data.story.url} target="_blank">
          <QRCode src={qrCode} />
          <Title>{data.story.title}</Title>
          <Separator />
        </HeaderWrapper>
        <SummaryWrapper>
          {data.story.imageUrl && <Image style={{ maxHeight: `${maxImageHeight}px` }} src={data.story.imageUrl} />}
          {textChunks?.map((text) => (
            <SummaryItem key={text}>
              <SummaryText>{text}</SummaryText>
            </SummaryItem>
          ))}
        </SummaryWrapper>
      </StoryPageContent>
    </>
  );
}

import { styled } from '@linaria/react';

export const MARGIN_VERTICAL_PX = 102;
export const MARGIN_HORIZONTAL_PX = 137;
export const GAP_PX = 18;
export const IMAGE_MARGIN_TOP_PX = 6;

export const PageConfig = `
  @page {
    width: 8in;
    height: 10in;
    margin: ${MARGIN_VERTICAL_PX}px ${MARGIN_HORIZONTAL_PX}px;
  }

  body {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: white;
  }
`;

export const StoryPageContent = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${GAP_PX}px;
`;

export const HeaderWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${GAP_PX}px;
`;

export const Title = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 0.319583in;
  line-height: 0.4475in;
  color: #141f1f;

  /* 
  During printing, the page margin is only applied at print time, and not while we are
  executing the javascript. So the header size in the javascript side will evaluated without
  taking margins in consideration.
  We need then to set the max-width to the max available width during printing to prevent
  overflowing issues with the image.
   */
  max-width: calc(8in - ${MARGIN_HORIZONTAL_PX}px * 2);
`;

export const SummaryText = styled.span`
  font-family: 'TimesRegular';
  text-align: center;
  font-size: 0.1666in;
  line-height: 0.25in;
  color: var(--book-text);
`;

export const Separator = styled.div`
  width: 140pt;
  height: 1pt;
  flex: 0 0 1pt;
  background: #cccccc;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 7px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    height: 4pt;
    width: 4pt;
    border-radius: 50%;
    background: #cccccc;
  }

  &:after {
    left: 0;
  }
  &:before {
    right: 0;
  }
`;

export const QRCode = styled.img`
  width: 80px;
  height: 80px;
`;

export const SummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SummaryItem = styled.p`
  line-height: 0.25in;
  text-align: justify;
  white-space: pre-wrap;
`;

export const Image = styled.img`
  object-fit: contain;
  margin-top: ${IMAGE_MARGIN_TOP_PX}px;
`;
